p {
    font-family: "Comic Sans MS";
    line-height: 1.5;
}

title {
    color: black;
}

.error {
    color: red;
    background: rgb(135, 209, 255);
    font-size: 1.5em;
    border-style: solid;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.foot {
    background-color: #f5f2e6
}

.bg {
    background-size: cover;
}

.left {
    float: left;
}

.right {
    float: right;
}


.times {
    font-family: 'Times New Roman';
    line-height: 2.0;
    font-size: 20px;
}